import React, { useEffect, useCallback } from "react";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { Typography, Button, IconButton } from '@mui/material';
import { useDispatch,useSelector, shallowEqual } from "react-redux";
import { SET_SELECTED, SET_SLIDEROPEN } from "../../Redux/Actions/userDataAction";
import { useNavigate } from "react-router-dom";
import { useAccount } from 'wagmi';
import "./sliderMenu.css";

const links = [
    { name: "Dashboard", to: "dashboard", id: 1 },
    { name: "Mining", to: "mining", id: 2 },
    { name: "Hunting", to: "hunting", id: 3 },
    { name: "Land", to: "land", id: 4 },
    { name: "Inventory", to: "inventory", id: 5 },
    { name: "Shop", to: "shop", id: 6 },
    { name: "Market", to: "market", id: 7 },
    { name: "Delegator", to: "delegator", id: 8 },
    { name: "Profile", to: "profile", id: 9 }
];

const itemVariants = {
    closed: {
        opacity: 0
    },
    open: { opacity: 1 }
};

const sideVariants = {
    closed: {
        transition: {
            staggerChildren: 0.2,
            staggerDirection: -1
        }
    },
    open: {
        transition: {
            staggerChildren: 0.2,
            staggerDirection: 1
        }
    }
};

export default function SliderMenu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, cycleOpen] = useCycle(false, () => true);
    const userData = useSelector((state) => state.UserDataReducer, shallowEqual);
    const selected = userData.selected;
    const { isConnected } = useAccount();

    const OnSelectClick = useCallback(
        (link) => {
            dispatch(SET_SELECTED(link));
            navigate(`/${link}`);
        },
        [dispatch, navigate]
    );

    useEffect(() => {
            dispatch(SET_SLIDEROPEN(open));
    }, [open]);

    return (
        isConnected && userData.loggedIn &&(
            <div className="parent" style={{zIndex:'20'}}>
                <div className="child-flex">
                    <AnimatePresence>
                        {open && (
                            <motion.aside
                                initial={{ width: 0 }}
                                animate={{
                                    width: 300
                                }}
                            >
                                <motion.div
                                    className="profile-section"
                                    initial="closed"
                                    animate="open"
                                    variants={sideVariants}>
                                    <img className="profile-bar" src={require('../../Assets/Base/Profile_bar.png')} />
                                    <Typography className="account">{userData?.account?.userName??'none'}.spr</Typography>
                                    <img className="profile-dp" src={require('../../Assets/Base/Profile_dp.png')} />
                                </motion.div>
                                <main>
                                    <motion.div
                                        className="container"
                                        initial="closed"
                                        animate="open"
                                        variants={sideVariants}
                                    >
                                        {links.map(({ name, to, id }) => (
                                            <motion.a
                                                key={id}
                                                whileHover={{ scale: 1.05 }}
                                                variants={itemVariants}
                                            >
                                                <div className="menuFlex">
                                                    <Button onClick={() => OnSelectClick(to)}>
                                                        <img
                                                            className="icon"
                                                            src={require(`../../Assets/Base/${name}_btn.png`)}
                                                            alt={name}
                                                            style={{
                                                                transform: selected === to ? "scale(1.1)" : "scale(1)",
                                                                filter: selected === to ? "brightness(1)" : "brightness(0.7)"
                                                            }}
                                                        />
                                                    </Button>
                                                </div>
                                            </motion.a>
                                        ))}
                                        <motion.a variants={itemVariants}>
                                            <div className="profile_div">
                                                <Button size="small" onClick={() => window.open('https://twitter.com/', '_blank')}>
                                                    <img className="profile_btn" src={require('../../Assets/twitter_btn.png')} alt="twitter" />
                                                </Button>
                                                <Button size="small" onClick={() => window.open('https://discord.com/', '_blank')}>
                                                    <img className="profile_btn" src={require('../../Assets/discord_btn.png')} alt="discord" />
                                                </Button>
                                            </div>
                                        </motion.a>
                                    </motion.div>
                                </main>
                            </motion.aside>
                        )}
                    </AnimatePresence>
                </div>
                <div className="btn-container">
                    <IconButton size="small" onClick={cycleOpen}>
                        <img className="menuIcon" src={require('../../Assets/Base/MenuIcon.png')} />
                    </IconButton>
                </div>
            </div>
        )
    );
}