import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAccount } from 'wagmi';
import { SET_STAMINA } from "../../Redux/Actions/userDataAction";
import './header.sass';

export default function Header() {
    const dispatch = useDispatch();
    const userDataReducer = useSelector((state) => state.UserDataReducer);
    const [balance, setBalanceData] = useState(userDataReducer?.playerData?.balance ?? {});
    const [pod, setPod] = useState(0);
    const [pow, setPow] = useState(0);
    const { isConnected } = useAccount();

    const handleStamina = () => {
        dispatch(SET_STAMINA(true));
    };
    
    useEffect(() => {
        setBalanceData(userDataReducer?.playerData?.balance ?? {});
        setPod(userDataReducer?.account?.pod ?? 0.1);
        setPow(userDataReducer?.account?.pow ?? 0.1);
    }, [userDataReducer?.playerData?.balance, userDataReducer?.account?.pod, userDataReducer?.account?.pow]);

    return (
        isConnected && (userDataReducer?.loggedIn??false) && (
            <div className="header">
                <div className="parentClass">
                    <div className="stats">
                        <img className="stats-img" alt="stat-img" src={require('../../Assets/Base/Gold_Stat_Bar.png')} />
                        <div className="gold-div">
                            <span>{(balance['GOLD']??0).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </div>
                    </div>
                    <div className="stats">
                        <img className="stats-img" alt="stat-img" src={require('../../Assets/Base/AOD_Stat_Bar.png')} />
                        <div className="gold-div">
                            <span>{(balance['AOD']?? 0).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </div>
                    </div>
                    <div className="stats">
                        <img className="stats-img" alt="stat-img" src={require('../../Assets/Base/POW_Stat_Bar.png')} />
                        <div className="stats-text-div">
                            <span>{pow.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="stats">
                        <img className="stats-img" alt="stat-img" src={require('../../Assets/Base/POD_Stat_Bar.png')} />
                        <div className="stats-text-div">
                            <span>{ pod.toFixed(2) }</span>
                        </div>
                    </div>
                    <div className="stats" style={{
                        cursor: 'pointer'
                    }}>
                        <img className="stats-img"
                            alt="stat-img" onClick={handleStamina} src={require('../../Assets/Base/Stamina_Stat_Bar.png')} />
                        <div className="stamina-div">
                            <div className="stamina-bar" style={{
                                width: `${!balance['STAMINA'] || isNaN(balance['STAMINA']) ? 0 : Math.min(balance['STAMINA'], 1000) / 10}%`
                            }}>
                                <span>{!balance['STAMINA'] || isNaN(balance['STAMINA']) ? 0 : Math.min(balance['STAMINA'], 1000).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span>
                            </div>
                        </div>
                    </div>
                    <img className="logo" alt="stat-img" src={require('../../Assets/Dino_logo.png')} />
                </div>
            </div>
        )
    );
}