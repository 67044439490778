import { CircularProgress } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Transition from "../Components/RouterTransition/transition";
import SliderMenu from "../Components/SliderMenu/sliderMenu";
import Header from "../Components/Header/header";
import ProtectedRoutes from "./protectedRoutes";
import "../index.sass";

const Loading = () => {
    return (
        <div
            className="center_align_container"
            style={{
                height: "100vh",
                backgroundColor: "#14141f",
            }}
        >
            <CircularProgress style={{ color: "white" }} />
        </div>
    );
};

const HomePageRef = lazy(() => import("../Components/Home/homePage"));
const CreateAccountRef = lazy(() => import("../Components/CreateAccount/createAccount"));
const DashboardRef = lazy(() => import("../Components/Dashboard/dashboard"));
const LeaderboardRef = lazy(() => import("../Components/Leaderboard/leaderboard"));
const LuckyWheelRef = lazy(() => import("../Components/LuckyWheel/wheel"));
const MiningRef = lazy(() => import("../Components/Mining/mining"));
const ShopRef = lazy(() => import("../Components/Shop/shop"));
const HuntingRef = lazy(() => import("../Components/Hunting/hunting"));
const ContinentRef = lazy(() => import("../Components/Hunting/continent"));
const HuntingLandSelectionRef = lazy(() => import("../Components/Hunting/land"));
const LandRef = lazy(() => import("../Components/Land/land"));
const LandSelectionRef = lazy(() => import("../Components/Land/addLand"));
const InventoryRef = lazy(() => import("../Components/Inventory/inventory"));
const MarketRef = lazy(() => import("../Components/Market/market"));
const DelegatorRef = lazy(() => import("../Components/Delegator/delegator"));
const ProfileRef = lazy(() => import("../Components/Profile/profile"));
const SeasonPassRef = lazy(() => import("../Components/SeasonPass/seasonPass"));
export default function CustomRoutes() {
    
    return (
        <BrowserRouter>
            <div className="relative-container">
                <SliderMenu />
                <Header />
                <AnimatePresence mode="wait">
                    <Routes>
                        <Route
                            key="home"
                            path="/home"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"home"}>
                                        <HomePageRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="createAccount"
                            path="/createAccount"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"createAccount"}>
                                        <CreateAccountRef />
                                    </Transition>
                                </Suspense>
                            }
                        />
                        <Route
                            key="dashboard"
                            path="/dashboard"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"dashboard"}>
                                            <DashboardRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="leaderboard"
                            path="/leaderboard"
                            element={
                                <ProtectedRoutes>
                                <Suspense fallback={<Loading />}>
                                    <Transition key={"leaderboard"}>
                                        <LeaderboardRef />
                                    </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="luckyWheel"
                            path="/luckyWheel"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"luckyWheel"}>
                                            <LuckyWheelRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            } 
                        />
                        <Route
                            key="mining"
                            path="/mining"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"mining"}>
                                            <MiningRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="shop"
                            path="/shop"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"shop"}>
                                            <ShopRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="hunting"
                            path="/hunting"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"hunting"}>
                                            <HuntingRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="continent"
                            path="/continent"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"continent"}>
                                            <ContinentRef />
                                    </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="land-selection"
                            path="/land-selection"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"land-selection"}>
                                            <HuntingLandSelectionRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="land"
                            path="/land"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"land"}>
                                            <LandRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="add-land"
                            path="/land/add-land"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"add-land"}>
                                            <LandSelectionRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="inventory"
                            path="/inventory"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"inventory"}>
                                            <InventoryRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="market"
                            path="/market"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"market"}>
                                            <MarketRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="delegator"
                            path="/delegator"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"delegator"}>
                                            <DelegatorRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="profile"
                            path="/profile"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"profile"}>
                                            <ProfileRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            key="season-pass"
                            path="/season-pass"
                            element={
                                <ProtectedRoutes>
                                    <Suspense fallback={<Loading />}>
                                        <Transition key={"season-pass"}>
                                            <SeasonPassRef />
                                        </Transition>
                                    </Suspense>
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/*"
                            element={<Navigate to="/createAccount" />}
                        />
                    </Routes>
                </AnimatePresence>
            </div>
        </BrowserRouter>
    );
}