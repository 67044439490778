import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import CustomRoutes from "./Routes/customRoutes";
import Notifications from "./Components/Notifications/notification";
import { RESET_NOTIFICATION } from "./Redux/Actions/notificationHandlerAction";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/redux";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  Theme
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, mainnet, optimism, arbitrum, sepolia, base, arbitrumSepolia } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { GoogleOAuthProvider } from '@react-oauth/google';

const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: "blur(8px)",
  },
  colors: {
    accentColor: 'white',
    accentColorForeground: 'rgba(0,0,0,0.7)',
    actionButtonBorder: 'rgba(0,0,0,0.7)',
    actionButtonBorderMobile: 'rgba(0,0,0,0.7)',
    actionButtonSecondaryBackground: "white",
    modalText: "white",
    modalBackground: "rgba(96, 124, 132, 0.8)",
    modalBorder: "black",
    generalBorder: "black",
    selectedOptionBorder: "white",
    menuItemBackground: "rgba(0,0,0,0.7)",
  },
  fonts: {
    body: "'berlin-sans-fb-demi-bold', sans-serif",
  },
  radii: {
    actionButton: '1rem',
    connectButton: '1rem',
    menuButton: '1rem',
    modal: '1rem',
    modalMobile: '1rem',
  },
  shadows: {
    selectedOption: 'white',
    selectedWallet: 'white',
  },
};

const { chains, publicClient } = configureChains(
  [bsc, mainnet, optimism, arbitrum, sepolia, base, arbitrumSepolia],
  [
    alchemyProvider({
      apiKey: "-E37C9iQUE1i4q7xldKGLiuuyryIfW5a",
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "DINO_WORLD",
  projectId: "0db6bf42fdb5f94da7835c47d16816bb",
  chains,
});
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
const AddIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      style={{ marginRight: "0.2em" }}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM11.664 22.864L5.92 17.12C5.296 16.496 5.296 15.488 5.92 14.864C6.544 14.24 7.552 14.24 8.176 14.864L12.8 19.472L23.808 8.464C24.432 7.84 25.44 7.84 26.064 8.464C26.688 9.088 26.688 10.096 26.064 10.72L13.92 22.864C13.312 23.488 12.288 23.488 11.664 22.864Z"
        fill="#2A9714"
      />
    </svg>
  );
};
const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      style={{ marginRight: "0.5em" }}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C12.8355 0 9.74207 0.938383 7.11088 2.69649C4.4797 4.45459 2.42894 6.95344 1.21793 9.87706C0.00693255 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77486 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9951 11.758 30.3078 7.69122 27.3083 4.69171C24.3088 1.69219 20.242 0.00490278 16 0ZM8.61539 17.8461C8.25025 17.8461 7.89332 17.7379 7.58972 17.535C7.28612 17.3322 7.0495 17.0438 6.90977 16.7065C6.77003 16.3691 6.73348 15.9979 6.80471 15.6398C6.87594 15.2817 7.05177 14.9528 7.30996 14.6946C7.56815 14.4364 7.8971 14.2605 8.25522 14.1893C8.61334 14.1181 8.98454 14.1546 9.32188 14.2944C9.65922 14.4341 9.94755 14.6707 10.1504 14.9743C10.3533 15.2779 10.4615 15.6349 10.4615 16C10.4615 16.4896 10.267 16.9592 9.92079 17.3054C9.57457 17.6516 9.10501 17.8461 8.61539 17.8461ZM16 17.8461C15.6349 17.8461 15.2779 17.7379 14.9743 17.535C14.6707 17.3322 14.4341 17.0438 14.2944 16.7065C14.1546 16.3691 14.1181 15.9979 14.1893 15.6398C14.2606 15.2817 14.4364 14.9528 14.6946 14.6946C14.9528 14.4364 15.2817 14.2605 15.6398 14.1893C15.998 14.1181 16.3692 14.1546 16.7065 14.2944C17.0438 14.4341 17.3322 14.6707 17.535 14.9743C17.7379 15.2779 17.8462 15.6349 17.8462 16C17.8461 16.4896 17.6516 16.9592 17.3054 17.3054C16.9592 17.6516 16.4896 17.8461 16 17.8461ZM23.3846 17.8461C23.0195 17.8461 22.6625 17.7379 22.3589 17.535C22.0554 17.3322 21.8187 17.0438 21.679 16.7065C21.5393 16.3691 21.5027 15.9979 21.5739 15.6398C21.6452 15.2817 21.821 14.9528 22.0792 14.6946C22.3374 14.4364 22.6663 14.2605 23.0245 14.1893C23.3826 14.1181 23.7538 14.1546 24.0911 14.2944C24.4284 14.4341 24.7168 14.6707 24.9196 14.9743C25.1225 15.2779 25.2308 15.6349 25.2308 16C25.2307 16.4896 25.0362 16.9592 24.69 17.3054C24.3438 17.6516 23.8742 17.8461 23.3846 17.8461Z"
        fill="#5142FC"
      />
    </svg>
  );
};
const DeleteIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      style={{ marginRight: "0.2em" }}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C24.832 0 32 7.168 32 16C32 24.832 24.832 32 16 32C7.168 32 0 24.832 0 16C0 7.168 7.168 0 16 0ZM16 17.6C16.88 17.6 17.6 16.88 17.6 16V9.6C17.6 8.72 16.88 8 16 8C15.12 8 14.4 8.72 14.4 9.6V16C14.4 16.88 15.12 17.6 16 17.6ZM14.4 24H17.6V20.8H14.4V24Z"
        fill="#BF1F1F"
      />
    </svg>
  );
};
function App(props) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={myCustomTheme}
      >
        <GoogleOAuthProvider clientId={"358917880572-ksdp69gt2qf3u4hv7nq08ej5ctglh89f.apps.googleusercontent.com"}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <SnackbarProvider
                maxSnack={3}
                style={{ backdropFilter: "blur(5px)", backgroundColor: "rgba(0,0,0,0.9)", fontWeight: "bold", border: "1px solid white", borderRadius: "1rem" }}
                iconVariant={{
                  success: <AddIcon />,
                  error: <DeleteIcon />,
                  info: <InfoIcon />,
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .SnackbarContent-root": {
                    color: "black",
                    bgcolor: "rgba(255,255,255,0.8)",
                    borderRadius: "36.5px 1px 36.5px 36.5px",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                  },
                }}
                autoHideDuration={1500}
                onClose={() => {
                  store.dispatch(RESET_NOTIFICATION());
                }}
              >
                <div className="App">
                  <Notifications />
                  <CustomRoutes />
                </div>
              </SnackbarProvider>
            </PersistGate>
          </Provider>
        </GoogleOAuthProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
export default App;