export const SET_LOGGED_IN = (userData) => ({
    type: "SET_LOGGED_IN",
    data: userData,
});
export const SET_ACCOUNT = (userData) => ({
    type: "SET_ACCOUNT",
    data: userData,
});
export const SET_SELECTED = (userData) => ({
    type: "SET_SELECTED",
    data: userData,
});
export const SET_SLIDEROPEN = (userData) => ({
    type: "SET_SLIDEROPEN",
    data: userData,
});
export const SET_TOKEN = (userData) => ({
    type: "SET_TOKEN",
    data: userData,
});
export const SET_PLAYERDATA = (userData) => ({
    type: "SET_PLAYERDATA",
    data: userData,
});
export const SET_SHOPDATA = (userData) => ({
    type: "SET_SHOPDATA",
    data: userData,
});
export const SET_MARKETDATA = (userData) => ({
    type: "SET_MARKETDATA",
    data: userData,
});
export const SET_STAMINA = (userData) => ({
    type: "SET_STAMINA",
    data: userData,
});
export const SET_CONTINENT = (userData) => ({
    type: "SET_CONTINENT",
    data: userData,
});
export const SET_DELEGATE_LAND = (userData) => ({
    type: "SET_DELEGATE_LAND",
    data: userData,
});
export const SET_DELEGATES = (userData) => ({
    type: "SET_DELEGATE",
    data: userData,
});
export const SET_HUNT = (userData) => ({
    type: "SET_HUNT",
    data: userData,
});
export const SET_STAMINA_DATA = (userData) => ({
    type: "SET_STAMINA_DATA",
    data: userData,
});
export const RESET_APP = () => ({
    type: "RESET_APP",
});
export const SET_SEASON_REWARDS = (userData) => ({
    type: "SET_SEASON_REWARDS",
    data: userData,
});
export const SET_WEB3 = (userData) => ({
    type: "SET_WEB3",
    data: userData,
});
export const SET_CONTRACT = (userData) => ({
    type: "SET_CONTRACT",
    data: userData,
});

