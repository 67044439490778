import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAccount } from "wagmi";

function ProtectedRoutes({ children }) {
    const { isConnected } = useAccount();
    const isLoggedin = useSelector((state) => state.UserDataReducer?.loggedIn??false);
    if (isConnected && isLoggedin) return children;
    return <Navigate to={"/createAccount"} />;
}

export default ProtectedRoutes;
