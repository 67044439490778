const userInitialState = {
    loggedIn: false,
    account: {},
    selected: "",
    sliderOpen: false,
    playerData: {},
    marketData: {},
    shopData: {},
    webInstance: null,
    contractInstance: null,
};

export const UserDataReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case "SET_LOGGED_IN":
            return { ...state, loggedIn: action.data };
        case "SET_ACCOUNT":
            return { ...state, account: action.data };
        case "SET_PLAYERDATA":
            return { ...state, playerData: action.data };
        case "SET_SELECTED":
            return { ...state, selected: action.data };
        case "SET_SLIDEROPEN":
            return { ...state, sliderOpen: action.data };
        case "SET_TOKEN":
            return { ...state, token: action.data };
        case "SET_SHOPDATA":
            return { ...state, shopData: action.data };
        case "SET_MARKETDATA":
            return { ...state, marketData: action.data };
        case "SET_STAMINA":
            return { ...state, stamina: action.data };
        case "SET_CONTINENT":
            return { ...state, continent: action.data };
        case "SET_DELEGATE_LAND":
            return { ...state, delegateLand: action.data };
        case "SET_DELEGATES":
            return { ...state, playerData: { ...state.playerData, delegates: action.data } };
        case "SET_HUNT":
            return { ...state, hunt: action.data };
        case "SET_STAMINA_DATA":
            return { ...state, staminaData: action.data };
        case "SET_SEASON_REWARDS":
            return { ...state, seasonRewards: action.data };
        case "SET_WEB3":
            return { ...state, webInstance: action.data };
        case "SET_CONTRACT":
            return { ...state, contractInstance: action.data };
        default:
            return state;
    }
};